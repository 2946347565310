.Toolbar {
    height: 56px;
    width: 100%;
    position: fixed;
    top:0;
    left:0;
    background-color:#703909;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    color: #ffffff;
    font-size: 20px;
}

@media (max-width: 500px) {
    .HideOnMobile {
        display: none;
    }
}