.MenuItem {
    margin: 0px;
    padding: 0px;
    display: block;
    width: 100%;
    align-items: center;
    }
    
    .MenuItem a {
        color: #8f5c2c;
        text-decoration: none;
        height: 100%;
        display: block;
        padding: 16px 10px;

    }
    
    .MenuItem a:hover,
    .MenuItem a:active,
    .MenuItem a.active {
    color: #40a4c8;
    }


@media (min-width: 500px) {
    .MenuItem {
        margin: 0px;
        padding: 0px;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
        }
        
        .MenuItem a {
            color:white;
            text-decoration: none;
            height: 100%;
            padding: 16px 10px;
            border-bottom: 4px solid transparent;
        
        }
        
        .MenuItem a:hover,
        .MenuItem a:active,
        .MenuItem a.active {
            background-color: #8f5c2c;
            border-bottom: 4px solid #40A4C8;
        }
}
