.SideBar {

    width: 280px;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 200;
    padding: 32px 16px;
    transition: transform 0.3s ease-out;
}

@media(min-width: 500px)
{
.SideBar {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}
.Close {
    transform: translateX(-100%);
}


.Logo {
    height: 30%;
}