.BreadTop {
    width:80%;
    height:20%;
    margin:auto;
    background: linear-gradient(#bc581e, #e27b36);
    border-radius: 50% 50% 0 0;
    position:relative;
  }
  
  .Seed {
    position: absolute;
    width: 10%;
    height: 15%;
    background-color:#fff;
    top: 50%;
    left: 30%;
    border-radius: 40%;
    transform: rotate(-20deg);
    box-shadow: inset -2px -3px #c9c9c9;
  }
  
  .Second {
    top:25%;
    left:50%;
    transform: rotate(20deg);
  }
  
  .Third {
    top:35%;
    left:78%;
    transform: rotate(-195deg);
  }
  
  .Fourth {
    top:45%;
    left:10%;
    transform: rotate(-170deg);
  }
  
  .Salad {
    width:90%;
    height:7%;
    margin:2% auto;
    background: linear-gradient(#228c1d, #91ce50);  
    border-radius: 20px;
  }
  
  .Meat {
    width: 80%;
    height: 8%;
    background: linear-gradient(#7f3608, #702e05);
    margin:2% auto;
    border-radius:14px;
  }
  
  .Cheese {
    width: 91%;
    height: 4.5%;
    background: linear-gradient(#f4d004, #d6bb22);
    margin:2% auto;
    border-radius:20px;
  }
  
  .Bacon {
    width: 80%;
    height: 3%;
    background: linear-gradient(#bf3813, #c45e38);
    margin:2% auto;
  }
  
  .BreadBottom {
    width:80%;
    height:10%;
    margin:auto;
    background: linear-gradient(#bc581e, #e27b36);
    border-radius: 0 0 20px 20px;
  }