.Burger {
    width:100%;
    height: 290px;
    margin: auto;
    text-align: center;
    overflow: scroll;
    font-weight: bold;
    font-size: 24px;
  }

  @media (min-width: 800px) and (min-height: 400px){
    .Burger{
        width: 700px;
        height: 500px;

    }
  }