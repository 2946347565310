.HamBurgerMenu {
   width: 40px;
    height: 100%;
   display: flex;
   flex-flow: column;
   justify-content: space-around;
   padding: 10px 0;
   box-sizing: border-box;

}

.HamBurgerMenu div {
    background-color: white;
    height: 3px;

}