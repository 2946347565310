.Logo {
    background-color: white;
    padding: 8px;
    height: 80%;
    box-sizing: border-box;
    border-radius: 5px;
  }
  
  .Logo img {
    height: 100%;
  }
  